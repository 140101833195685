import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Hidden from "@material-ui/core/Hidden"
import FabDownload from "./fab-download"
import { Link } from "gatsby"

const Footer = () => {
  const footerLinks = [
    {
      title: "Compañía",
      items: [
        { displayText: "Contacto", url: "/contacto" },
        { displayText: "", url: "" },
        { displayText: "", url: "" },
      ],
    },
    {
      title: "Kit covid test",
      items: [
        { displayText: "Cómo funciona", url: "/" },
        { displayText: "Preguntas frecuentes", url: "/" },
        { displayText: "Manual de uso", url: "/" },
      ],
    },
  ]

  return (
    <React.Fragment>
      <Box className="footer">
        <Grid
          container
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            minHeight: 250,
            color: "#FFF",
          }}
        >
          <Grid
            xs={12}
            sm={6}
            item
            container
            direction="column"
            justify="space-evenly"
            style={{ padding: 10 }}
          >
            <Typography variant="h4" color="inherit">
              Farcorpe
            </Typography>
            <Typography variant="body2" color="inherit">
              Más de 25 años de experiencia en la compra, venta, importación y
              distribución de materias primas para la industria farmacéutica.
            </Typography>
            <Typography variant="body2" color="inherit">
              Farcorpe S.A. de S.V.
            </Typography>
          </Grid>

          <Hidden xsDown>
            {footerLinks.map(footerMenu => (
              <Grid
                item
                xs={12}
                sm={2}
                container
                direction="column"
                justify="space-evenly"
                key={footerMenu.title}
              >
                <Typography variant="subtitle1" color="inherit">
                  {footerMenu.title}
                </Typography>
                {footerMenu.items.map(link => (
                  <Link to={link.url}>{link.displayText}</Link>
                ))}
              </Grid>
            ))}
          </Hidden>
        </Grid>
      </Box>
      <Box className="attribution">
        <Grid
          container
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            color: "#FFF",
            paddingLeft: 5,
            paddingRight: 5,
          }}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Typography variant="body2" color="inherit">
            <Link to="/aviso" style={{ color: "#FFF", margin: 5 }}>
              Aviso de privacidad
            </Link>
          </Typography>
        </Grid>
        <FabDownload />
      </Box>
    </React.Fragment>
  )
}
export default Footer
